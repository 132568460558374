export function placementMapExample() {
  const element = document.querySelector('#example-wrapper__right-panel-content');
  const b3Gis = b3GisLib.init(element, {
    backendUser: 'use_your_user_name',
    backendPassword: 'use_your_password',
    layerTreeVisible: false,
    mapName: 'base-map',
    additionalMaps: ['placement-demo'],
    backendUrl: 'https://gis.big3.ru/',
    glyphs: 'https://205129.selcdn.ru/b3-gis/map-fonts'
  });
  b3Gis.setGisState({
    editingLayerId: 'placement',
    layers: {
      placement: {
        fit: {
          animate: false,
          maxZoom: 14
        },
        geoJson: {
          type: 'Feature',
          properties: {
            id: 1,
            color: '#491ad6'
            //icon: 'map-icon-trash' //'\ue906'
          },
          geometry: {
            type: 'Point',
            coordinates: [33, 55]
          }
        }
      }
    }
  });

  window.setTimeout(() => {
    b3Gis.setGisState({
      editingLayerId: 'placement',
      layers: {
        placement: {
          fit: {
            animate: false,
            maxZoom: 14
          },
          geoJson: {
            type: 'Point',
            coordinates: ['33.05', 55]
          }
        }
      }
    });
  }, 3000);
}
